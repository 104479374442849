import React, { useState, useEffect } from "react";
import Modal from "new/components/Modal/Modal";
import InputForm from "new/components/InputForm/InputForm";

import { Box, Button, Grid } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { maskPhone, unmask, validateCpf, validateCNPJ } from "helpers/util";
import { phoneNumberTest } from "helpers/phoneNumberTest";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";

import {
  createCustomer,
} from "../../../../helpers/apiHelper";

import "./UserModal.scss";
import { useDispatch, useSelector } from "react-redux";
import { userInfoChanged, clearUserInfo } from "actions/userInfoActions";
import { setZoneForRequestedCep } from "actions/setZoneForRequestedCep";
import { getAccessToken, handleTokenErrors } from "helpers/apis/oAuthApi";
import moment from "moment";
import { autoRegisterCustomerOnCRM } from "helpers/registerCRMIdever";
import LoginFailureModal from "../LoginFailureModal";
import { notification } from "helpers/util";

function formatDate(date) {
  return moment(date).isValid();
}


const client_infos_schema = Yup.object().shape({
  name: Yup.string().min(5).required("Campo obrigatório"),
  cpf: Yup.string().required("Campo obrigatório"),
  phone_number: Yup.string()
    .test("phone", "O número de telefone deve ser válido", phoneNumberTest)
    .required("Campo obrigatório"),
  birthDate: Yup.string()
    .required('Campo obrigatório')
    .test("birthDate", "Data de aniversário deve ser uma data válida.", formatDate),
  email: Yup.string().email().required("Campo obrigatório"),
});




export function UserModal({ isOpen, firebaseRecoveredInfo }) {
  const localID = localStorage.getItem("USER_ID");
  const brand = useSelector(({brand}) => brand.brand)
  const history = useHistory();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(isOpen);
  const [cpfInput, setCpfInput] = useState({
    mask: "999.999.999-99",
    validate: validateCpf
  })
  const userInfo = useSelector(({ userInfo }) => userInfo)
  var crmUserCpf = localStorage.getItem("crmUserCpf")
  const [loginFailureModal, setOpenLoginFailureModal] = useState({ open: false, error: null})

  const handleLengthCpf = (e) => {
    const {value} = e.target
    const cpf = unmask(value)

    if(cpf.length <= 11){
      setCpfInput({
        mask: "999.999.999-999",
        validate: validateCpf
      })
    }else if(cpf.length >= 12){
      setCpfInput({
        mask: "99.999.999/9999-99",
        validate: validateCNPJ
      }) 
    }
  }

  const tryToLogin = async (cpf) => {
    const response = await getAccessToken(cpf)
    if (response.data) {
      handleTokenErrors(response.data, (error) => setOpenLoginFailureModal({ open: true, error: error }))
    }
    return response?.access_token !== undefined
  }

  async function onSubmit(values) {
    const cpfValue = unmask(values.cpf)
    const formattedValues = ({ ...values, cpf: cpfValue })
    const loginSucceeded = await tryToLogin(cpfValue)
    loginSucceeded
      ? setShowModal(false)
      : createNewCustomer(formattedValues, brand.id)
    localStorage.setItem("personType", cpfValue.length <= 11 ? "physical" : "juridical")
  }

  const handleAutoRegisterCustomerOnCRM = async (brandId, customerId, customerCpf) => {
    if(!crmUserCpf ){
      const response = await autoRegisterCustomerOnCRM(brandId, customerId)
      if(response.status === 200 || response.status === 201){
        localStorage.setItem("crmUserCpf", customerCpf);
      }
    }
  }

  const handleCustomerData = (data, brandId) => {
    localStorage.setItem("USER_ID", data.customer.id);
    dispatch(userInfoChanged({
      ...userInfo,
      brand_customer: data.customer.brand_customer,
      birth_date: data.customer.birth_date,
      push: "e062f13a-ef70-11ed-a05b-mercadapp",
      id: data.customer.id,
      invitation: data.customer.invitation,
      total_invitations: data.customer.total_invitations,
      verified: data.customer.verified,
      name: data.customer.name,
      cpf: data.customer.cpf,
      email: data.customer.email,
      sex: data.customer.sex,
      terms_of_use_version: data.customer.terms_of_use_version,
      phone_number: data.customer.phone_number,
    }));
    setShowModal(false);
    handleAutoRegisterCustomerOnCRM(brandId, data.customer.id, data.customer.cpf);
  };
  
  const createNewCustomer = async (customerData, brandId) => {
    try {
      const { data } = await createCustomer(customerData, brandId);
      const loginSucceeded = await tryToLogin(data.customer.cpf)
      if (loginSucceeded) {
        handleCustomerData(data, brandId);
        setShowModal(false);
      }
    } catch (error) {
      console.log(error);
      notification("Erro ao criar novo usuário.", "danger")
    }
  };


  function logout() {
    localStorage.removeItem("USERNAME");
    localStorage.removeItem("USERPHONE");
    localStorage.removeItem("USER_ID");
    localStorage.removeItem("idToken")
    dispatch(clearUserInfo());
    firebase.auth().signOut();
    history.push("/");
    dispatch(setZoneForRequestedCep({ data: { cep: null } }))
  }


  return (
    <>
    <Modal isOpen={showModal}>
      <Box className="title">
        <h5>Insira suas informações</h5>
        <Button color="primary" onClick={() => logout()}>
          Cancelar
        </Button>
      </Box>
      <Formik
        initialValues={{
          name: firebaseRecoveredInfo?.name || "",
          cpf: "",
          email: firebaseRecoveredInfo?.email || "",
          phone_number: maskPhone(firebaseRecoveredInfo?.phone_number) || "",
          birthDate: ""
        }}
        validationSchema={client_infos_schema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ values }) => (
          <Form noValidate>
            <Box>
              <Grid container className="order-delivery-inputs-form">
                <Grid item className="input-item read-only" xs={12}>
                  <Field
                    name="name"
                    label="Nome"
                    type="text"
                    total_width={true}
                    required
                    component={InputForm}
                  />
                </Grid>
                <Grid item className="input-item read-only" xs={12}>
                  <Field
                    name="cpf"
                    label='CPF ou CNPJ' 
                    type="text"
                    mask={cpfInput.mask}
                    total_width={true}
                    required
                    validate={cpfInput.validate}
                    onChange={(e) => handleLengthCpf(e)}
                    component={InputForm}
                  />
                </Grid>
                <Grid item className="input-item" xs={12}>
                  <Field
                    name="email"
                    label="Email"
                    type="text"
                    total_width={true}
                    required
                    component={InputForm}
                  />
                </Grid>
                <Grid item className="input-item" xs={12}>
                  <Field
                    name="phone_number"
                    label="Telefone"
                    readOnly={firebaseRecoveredInfo?.phone_number}
                    type="text"
                    mask="(99)99999-9999"
                    total_width={true}
                    required
                    component={InputForm}
                  />
                </Grid>
                <Grid item className="input-item" xs={12}>
                  <Field
                    name="birthDate"
                    label="Data de Nascimento"
                    type="date"
                    total_width={true}
                    required
                    component={InputForm}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={3} className="modal-client-footer">
              <span className="errors-infos">
                *Os seguintes campos devem ser preenchidos:
                {`
                    ${!values.name ? "nome completo," : ""}
                    ${!values.cpf ? " cpf," : ""}
                    ${!values.phone_number ? " telefone," : ""}
                  ${!values.email ? " email," : ""}`}
              </span>
              <span className="errors-infos">
                *Você pode alterar o telefone na tela de perfil.
              </span>
              <Button
                variant="contained"
                type="submit"
                color="primary"
              >
                Confirmar
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
    <LoginFailureModal
      isOpen={loginFailureModal.open}
      setIsOpen={(bool) => setOpenLoginFailureModal({ open: bool, error: null })}
      onConfirm={logout}
      errorMessage={loginFailureModal.error}
    />
    </>
  );
}